import React from 'react';


const ContactUsPage = (props) =>{
    return(
        <main className="container mt-5">
            <div className="row row-content">

                <div className="col-12 col-sm-6">
                    <div className="">
                        <h3>Телефоны:</h3>
                        <p>+380 95 431 5882</p>
                        <h2>График работы:</h2>
                        <p>Пн-Пт 8:00-18:00<br/>Сб 8:00-14:00</p>
                    </div>
                </div>

                <div className="col-12 col-sm-6">
                    <div >
                        <h2>Наш адрес: Полтава, ул. Кагамлыка 37а</h2>
                        <div className="map">

                            <iframe title="map" className="map"src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d914.9323007934464!2d34.53897727471927!3d49.56343190865499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDnCsDMzJzQ4LjEiTiAzNMKwMzInMjIuNCJF!5e0!3m2!1sru!2sus!4v1627488633406!5m2!1sru!2sus" height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ContactUsPage;