import React from 'react';
import Details from '../Details'
import signalImage from '../../img/signal.jpg'
import Gallery from '../../Gallery';

const data = [{image :'/OurWork/signal_1.jpg', thumb:'/OurWork/signal_1.jpg',label:'first'},
                {image :'/OurWork/signal_4.jpg', thumb:'/OurWork/signal_4.jpg',label:'second'},
                {image :'/OurWork/signal_2.jpg', thumb:'/OurWork/signal_2.jpg',label:'third'},
                {image :'/OurWork/signal_3.jpg', thumb:'/OurWork/signal_3.jpg',label:'fourth'}];

const SignalizationPage = (props) =>{
    return(
        <main>
            <div className="container mt-5">
                <div className="row row-header">
                    <div className="col-12">
                        <h1>Автосигнализация</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6">

                    Автосигнализация не только закрывает двери, но и защищает автомобиль от проникновения в салон злоумышленников, и от угона вашего автомобиля.
                    <br/>
                    <br/>
                    В комплект автосигнализации может входить:<br/>
                    <ul>
                        <li>датчик удара</li>
                        <li>центральный блок</li>
                        <li>брелки управления</li>
                        <li>кнопка Valet</li>
                        <li>блокировка двигателя</li>
                    </ul>

                    
                </div>
                    <div className="col-12 col-sm-6">
                        <img src={signalImage} className="tinted-card" alt="Автосигнализация"/>
                    </div>

                </div>
                <div className="container">
                    <Details/>
                </div>
                <div class="row justify-content-center mb-2">
                    <h3>Наши работы</h3>
                </div>
                <Gallery data={data}/>
            </div>
            
            
        </main>

    );
}

export default SignalizationPage;