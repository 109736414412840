import React from 'react';
import Details from '../Details'
import straightenImage from '../../img/straight_page.jpeg'
import Gallery from '../../Gallery';

const data = [{image :'/Straight/1.jpeg', thumb:'/Straight/1.jpeg',label:'first'},
                {image :'/Straight/9.jpeg', thumb:'/Straight/9.jpeg',label:'second'},
                {image :'/Straight/3.jpeg', thumb:'/Straight/3.jpeg',label:'third'},
                {image :'/Straight/11.jpg', thumb:'/Straight/11.jpg',label:'tenth'},
                {image :'/Straight/5.jpeg', thumb:'/Straight/5.jpeg',label:'fifth'},
                {image :'/Straight/15.jpg', thumb:'/Straight/15.jpg',label:'tenth'},
                {image :'/Straight/7.jpeg', thumb:'/Straight/7.jpeg',label:'seventh'},
                {image :'/Straight/8.jpeg', thumb:'/Straight/8.jpeg',label:'sixth'},
                {image :'/Straight/10.jpeg', thumb:'/Straight/10.jpeg',label:'tenth'},
                {image :'/Straight/4.jpeg', thumb:'/Straight/4.jpeg',label:'fourth'},
                {image :'/Straight/12.jpg', thumb:'/Straight/12.jpg',label:'tenth'},
                {image :'/Straight/14.jpg', thumb:'/Straight/14.jpg',label:'tenth'},];

const StraightenPage = (props) =>{
    return(
        <main>

<div class="container mt-5">
        <div class="row row-header">
            <div class="col-12">
                <h1>Рихтовка авто</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6">
            <p>
              Каждая вмятина портит внешний вид вашего автомобиля. Более того, вмятины могут снизить предпродажную оценочную стоимость автомобиля.
              <br/><br/>
              Рихтовка автомобиля - это идеальное средство борьбы с такими повреждениями. 
              <br/>
              Все вмятины удаляются полностью, позволяя вернуть автомобиль к заводскому состоянию.

          </p>
        </div>
            <div class="col-12 col-sm-6">
                <img src={straightenImage} class="tinted-card" alt=""/>
            </div>

        </div>
        <div class="container">
            <Details/>
        </div>
   
   

        <div class="row justify-content-center mb-2">
            <h3>Наши работы</h3>
        </div>
        <Gallery data={data}/>
    </div>
</main>
    );
}

export default StraightenPage;