import React, { Component } from 'react';

import '../../node_modules/@fancyapps/ui/dist/fancybox.umd'
import "@fancyapps/ui/dist/fancybox.css";

class Gallery extends Component{
    render(){

        const images = [...this.props.data];


        let elements = images.map((item) => {
            return(
            <div className="col-lg-3 col-md-4 col-6 thumb">
                <a data-fancybox="gallery" href={item.image}>
                    <img className="img-fluid" src={item.thumb} alt={item.label}/>
                </a>
             </div>)
        ;
        });
        return(

            <div className="container mb-3">
                
            <div className="row">
                {elements}
            </div>
        </div>
    );
    }
}





export default Gallery;
