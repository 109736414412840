import React from 'react';

import Details from '../Details'
import Gallery from '../../Gallery';
import tintedImage from '../../img/test_tinted.jpeg'

const data = [ 
                {image :'/OurWork/15.jpg', thumb:'/OurWork/15.jpg',label:'first'},
                {image :'/OurWork/13.jpg', thumb:'/OurWork/13.jpg',label:'first'},
                {image :'/OurWork/18.jpg', thumb:'/OurWork/18.jpg',label:'first'},
                {image :'/OurWork/12.jpg', thumb:'/OurWork/12.jpg',label:'first'},
                {image :'/OurWork/3.jpeg', thumb:'/OurWork/3.jpeg',label:'third'},
                {image :'/OurWork/8.jpg', thumb:'/OurWork/8.jpg',label:'third'},
                {image :'/OurWork/16.jpg', thumb:'/OurWork/16.jpg',label:'first'},
                {image :'/OurWork/1.jpeg', thumb:'/OurWork/1.jpeg',label:'first'},
                {image :'/OurWork/19.jpg', thumb:'/OurWork/19.jpg',label:'third'},
                {image :'/OurWork/2.jpeg', thumb:'/OurWork/2_little.png',label:'second'},
                {image :'/OurWork/4.jpeg', thumb:'/OurWork/4.jpeg',label:'fourth'},
                {image :'/OurWork/20.jpg', thumb:'/OurWork/20.jpg',label:'third'},
                {image :'/OurWork/5.jpeg', thumb:'/OurWork/5.jpeg',label:'fifth'},
                {image :'/OurWork/6.jpeg', thumb:'/OurWork/6.jpeg',label:'sixth'},
                {image :'/OurWork/11.jpg', thumb:'/OurWork/11.jpg',label:'third'},
                {image :'/OurWork/17.jpg', thumb:'/OurWork/17.jpg',label:'third'},
                {image :'/OurWork/9.jpg', thumb:'/OurWork/9.jpg',label:'third'},
                {image :'/OurWork/10.jpg', thumb:'/OurWork/10.jpg',label:'third'},
                {image :'/OurWork/7.jpeg', thumb:'/OurWork/7.jpeg',label:'seventh'}];

const TintingPage = (props) =>{
    return(
        <main>
            <div className="container mt-5 ">
                <div className="row row-content">
            
                <div className="col-12 col-md-6">
                    <h1> Тонирования стекол</h1>
                    <p>Сегодня автомобиль является не только средством для передвижения, а нечто большим: индивидуальным и оригинальным.<br/>
                        <br/>
                        Тонирования автомобиля - идеальное средством, которое сделает ваш автомобиль более эффектным и элегантным <br/>
                        <br/>
                        <br/>
                        Мы предоставляем услуги:<br/>
                        <ul>
                            <li>Тонировки Антибликовой и Атермальной пленки</li>
                            <li>Растонировка</li>
                            <li>Бронирование стекол</li>
                            
                        </ul>
                        <br/>
                        </p>
                </div>
                <div className="col-12 col-md-6">
                    <img src="/OurWork/15.jpg" className="tinted-card" alt=""/>
                </div>
                </div>
                <div className="row m-auto" >
                    <div className="col-12 row-header mb-2">
                        <h2 className="">
                            <span className="fa fa-question-circle " aria-hidden="true"></span>
                            Для чего нужна тонировка машины?</h2>
                    </div>

                    <ul>
                        <li>
                            <div className="col-12 col-sm-10">
                            <h4>Комфорт в жару</h4>
                            <p>Обеспечивает комфорт водителя во время жары и защищает детали салона от воздействия солнечных лучей</p>
                        </div>
                        </li>
                        <li>
                            <div className="col-12 col-sm-10">
                                <h4>Комфорт в жару </h4>
                                <p>Обеспечивает комфорт водителя во время жары и защищает детали салона от воздействия солнечных лучей</p>
                            </div>
                        </li>
                        <li>
                            <div className="col-12 col-sm-10">
                                <h4>Красота</h4>
                                <p>Улучшает эстетический вид автомобиля, добавляет солидности и подчеркивает его неповторимость</p>
                            </div>
                        </li>
                        <li>
                            <div className="col-12 col-sm-10" >
                                <h4>Анонимность на дороге</h4>
                                <p>Cкроет вас от посторонних глаз. Добавит комфорт и уют вашему автомобилю.</p>
                            </div>
                        </li>
                        
                    </ul>

                    
                    
                </div>
                    <div className="row row-content">
                        <div className="col-12 row-header">
                            <h2>
                                <span className="fa fa-flag " aria-hidden="true"></span>
                                От чего зависит цена тонировки?</h2>
                    </div>
                        <div className="col-12">
                        <p>Цена тонировки стекол машины может быть разной в зависимости от:</p>
                            <ul>
                                <li>класса машины;</li>
                                <li>количества тонируемых элементов;</li>
                                <li>выбора пленки;</li>
                                <li>способа нанесения тонировки.</li>
                            </ul>
                            <div className="container"><Details/></div>
                           
                        </div>
                    </div>
                    <div className="row justify-content-center mb-2">
                        <h3>Наши работы</h3>
                    </div>

                    <Gallery data={data}/>
            </div>

        </main>
    );
}

export default TintingPage;