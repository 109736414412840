import React, { useState } from 'react';
import '../../node_modules/popper.js/dist/umd/popper'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { Link } from 'react-router-dom';
import sl1 from '../img/sl2.jpg'
import sl2 from '../img/antigrav_slider.jpeg'
import sl3 from '../img/sl3.jpeg'


const items = [
    {
      src: sl1,
      altText: 'Тонировка автомобиля',
      caption: 'Надёжная защита на долгие годы',
      link: '/tinting'
    },
    {
      src: sl2,
      altText: 'Антигравийная пленка',
      caption: 'Скажи нет царапинам и сколам',
      link: '/antigrav'
    },
    {
      src: sl3,
      altText: 'Автомобильная сигнализация',
      caption: 'Идеальная защита для вашего автомобиля',
      link: '/signalization'
    }
  ];
  
  const MyCarousel= (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    }
  
    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.src}
        >
          <img className="d-block w-100 slider-img" src={item.src} alt={item.altText} />
        <div className="carousel-caption">
            <h5 className="slider-header">{item.altText}</h5>
            <p className="slider-text">{item.caption}</p>
            <Link to={item.link} className="btn btn-slider">Узнать больше</Link>
        </div>
       
          
        </CarouselItem>
      );
    });
  
    return (
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        
        {slides}
        <CarouselControl direction="prev" onClickHandler={previous} />
        <CarouselControl direction="next"  onClickHandler={next} />
      </Carousel>
    );
  }
  
  export default MyCarousel;