import React from 'react';
import Details from '../Details/Details';
import autoImage from '../../img/usa_page.jpeg'

const AutoFromUSAPage = (props) =>{
    return(
        <main>
            <div className="container mt-5">
            

                <div className="row">
                    
                    <div className="col-12 col-md-6 ">
                        <h1>Авто из США</h1>
                        <p>Занимаемся подбором автомобилей под ключ. </p>
                        <p> Каждый автомобиль  проходит 8 этапов тщательной проверки, которые позволят нам узнать количество владельцев, был ли автомобиль в ДТП, историю обслуживания.
                             Все данные мы предоставляем клиенту.<br/>
                            Предоставляем возможность ремонта автомобиля в нашем СТО.
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src={autoImage} className="tinted-card" alt="Авто из США"/>
                    </div>
                </div>
                <div className="container">
                    <Details/>
                </div>
            </div>
        </main>
    );
}

export default AutoFromUSAPage;