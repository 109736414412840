import React from 'react';
import Details from '../Details'
import antigravPageImage from '../../img/antigrav_page.jpeg'


const AntigravPage = (props) =>{
    return(

        <main>
            <div className="container mt-3">
                <div className="row row-content">
                    <div className="col-12 mb-3">
                        <h1>Антигравийная защита</h1>
                    </div>
                    <div className="col-12 col-sm-6">
                        <p>На каждом автомобиле могут появиться царапины и сколы, которые могут подпортить эстетический вид автомобиля.<br/>
                        <br/>
                        Покраска машины может обойтись в приличную сумму. Несмотря на это крашеная машина может отпугнуть потенциальных покупателей. <br/><br/>
                    Защитить ваше авто от такого вида повреждений может антигравийная пленка. Это эластичное покрытие, которым обклеивают поверхность кузова.</p>
                    </div>
                    <div className="col-12 col-sm-6">
                        <img src={antigravPageImage} className="tinted-card" alt="Антигравийная защита"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p> Антигравийная пленка защищает от:</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <ul className="colored_list">
                        <li>царапин, сколов, легких кузовных контактов;</li>
                        <li>химических реагентов, влаги;</li>
                        <li>песка, грязи.</li>
                    </ul>
                    </div>
                </div>
                <div className="row">
                <div className="col-12">
                <p> Антигравийной  плёнкой можно обклеить как всю машину, так и её отдельные части:
                <br/></p>
                    <ul className="colored_list">
                        <li>Места хвата ручек — часто они царапаются ключом,</li>
                        <li>Бампера — на бампер чаще всего летит мусор с дороги, так же он чаще всего страдает от потёртости,</li>
                        <li>Капот, крыша, передние крылья — на этих местах чаще всего появляются сколы.</li>
                    </ul>
                </div>
                </div>
                <div className="container">
                    <Details/>
                 </div>
            
            
            </div>
        </main>
    );
}

export default AntigravPage;