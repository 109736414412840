import React, {useState, useEffect} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Container,
    NavbarBrand
  } from 'reactstrap';
import {Link} from 'react-router-dom'


const Header = (props) =>{
 
        const [isOpen, setIsOpen] = useState(false);

        const toggle = () => setIsOpen(!isOpen);

        const hide = () =>{
          if(isOpen)
            toggle();
        }
        
        return(
    <header>
        <Navbar dark expand="sm" fixed="top" >
            <Container>
           
            <NavbarToggler onClick={toggle} />
            
            
            <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar >
          <NavbarBrand href="/"><img src="/favicon.ico" className="mainIcon" alt="icon"/></NavbarBrand>
            <NavItem>
              <Link to="/" className="nav-link active"  onClick={hide} >Главная</Link>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Услуги
              </DropdownToggle>
              <DropdownMenu  className="dropdown-menu" >
                <DropdownItem >
                    <Link to='/tinting' className="dropdown-item"  onClick={hide} >Тонировка стекол</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/antigrav' className="dropdown-item"  onClick={hide} >Антигравийная защита</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/straight' className="dropdown-item"  onClick={hide} >Рихтовка автомобиля</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/signalization' className="dropdown-item"  onClick={hide} >Автомобильная сигнализация</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/fromUSA' className="dropdown-item"  onClick={hide} >Авто из США</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/anticor' className="dropdown-item"  onClick={hide} >Антикор</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/GBO' className="dropdown-item"  onClick={hide} >Установка ГБО</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/vinil' className="dropdown-item"  onClick={hide} >Оклейка винилом</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/autoParts' className="dropdown-item"  onClick={hide} >Автозапчасти</Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to='/noise' className="dropdown-item"  onClick={hide} >Шумоизоляция</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
            <Link to="/contactUs" className="nav-link "  onClick={hide} >Контакты</Link>
            </NavItem>
          </Nav>
          
        </Collapse>
        <NavbarText className="headerLinkItem active mr-2">
          <span>Кагамлыка 37а</span>
        </NavbarText>

        <NavbarText className="headerLinkItem active">
          
         
          <a href="tel:+380954315882">+380 95 431 5882</a>
        </NavbarText>
        </Container>
        </Navbar>
    </header>
        );
    
}

export default Header;