import React from 'react';

const Details = (props) =>{
    return(
        <div className="row mt-5 mb-5">
            <p className="phoneForDetail">За более детальной информацией звоните по номеру: </p> 
            <ul class="colored_list">
                
                <li><a href="tel:+380954315882">+380 95 431 5882</a></li>
            </ul>
        </div>
    );
}

export default Details;