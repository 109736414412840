import React from 'react';
import { Container} from 'reactstrap';
import  MyCarousel from '../../MyCarousel'
import { Link } from 'react-router-dom';
import tintedImage from '../../img/tint1.jpg'
import antigravImage from '../../img/antigrav1.jpg'
import straightImage from '../../img/straight_block.jpeg'
import signalImage  from '../../img/signal.jpg'
import autosImage  from '../../img/fromUsa.jpeg'
import anticorImage from '../../img/anticor.jpeg'
import GBOImage from '../../img/GBO.jpg'
import vinilImage from '../../img/vinil.jpeg'
import autoPartsImage from '../../img/autoparts.jpeg'
import noiseImage from '../../img/noise.jpg'

const MainPage = (props)=>{
    return(
        <>
        <MyCarousel/>
        <div className="container ">
            <div className="row mt-5 title-wrapper">
                <div className="col-12 title">
                    <h1>О нас</h1>
                </div>
            </div>
        <div className="row mb-5">
                <div className="col-12 offset-md-3 col-sm-6">
                    <p className="description">Установочный центр SaturnAvto предоставляет услуги: тонировки автомобиля, установки охранных систем, установки ГБО, рихтовки с 2002.
                     В нашей работе основной акцент направлен на использовании только качественных товаров и материалов, которые прошли многолетнее тестирование.
                   </p>
                </div>
         </div>    
      </div>
        
        <main className="main">
            <Container pt="5">
                <div class="row ">
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/tinting">
                            <div class="card  text-white">

                                <img class="card-img" src={tintedImage} alt="Tinted" />
                                <div class="overlay-div "></div>
                                <div class="card-img-overlay ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Тонировка стекол</h4>
                                    </div>
                                </div>

                            </div>  
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/antigrav">
                            <div class="card  text-white">
                                <img class="card-img" src={antigravImage} alt="Antigrav" />
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Антигравийная защита</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/straight">
                            <div class="card  text-white">
                                <img class="card-img" src={straightImage} alt="Рихтовка"/>
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay  ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Рихтовка автомобиля</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                
                </div>  
               
                <div class="row">
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/signalization">
                            <div class="card  text-white">

                                <img class="card-img" src={signalImage} alt="Автомобильная сигнализация" />
                                <div class="overlay-div "></div>
                                <div class="card-img-overlay ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Автомобильная сигнализация</h4>
                                    </div>
                                </div>

                            </div>  
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/fromUSA">
                            <div class="card  text-white">
                                <img class="card-img" src={autosImage} alt="Авто из США" />
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Авто из США</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/anticor">
                            <div class="card  text-white">
                                <img class="card-img" src={anticorImage} alt="Антикор"/>
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay  ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Антикор</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                
                </div>  
                <div class="row pb-5">
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/GBO">
                            <div class="card  text-white">

                                <img class="card-img" src={GBOImage} alt="Установка ГБО" />
                                <div class="overlay-div "></div>
                                <div class="card-img-overlay ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Установка ГБО</h4>
                                    </div>
                                </div>

                            </div>  
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/vinil">
                            <div class="card  text-white">
                                <img class="card-img" src={vinilImage} alt="Оклейка винилом" />
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Оклейка винилом</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/autoParts">
                            <div class="card  text-white">
                                <img class="card-img" src={autoPartsImage} alt="Автозапчасти"/>
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay  ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Автозапчасти</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-12 col-sm-4 pt-2 pb-2">
                        <Link to="/noise">
                            <div class="card  text-white">
                                <img class="card-img" src={noiseImage} alt="Шумоизоляция"/>
                                <div class="overlay-div"></div>
                                <div class="card-img-overlay  ">
                                    <div class="item">
                                        <h4 class="card-title align-middle">Шумоизоляция</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    
                
                </div>  
              
            </Container>
        </main>


        </>
    );
}

export default MainPage