import React from 'react';
import Details from '../Details';

const AnticorPage = (props) => {
    return(
        <main>
            <div className="container mt-5">
                <div className="row row-header">
                    <div className="col-12">
                        <h1>Антикор</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 ">
                    <p>Антикор(РАСТ СТОП) - надёжный способ защиты автомобиля от коррозии. <br/>
                        Антикоррозионная защита наносится на внешние и внутренние детали, защищая ваш автомобиль от негативного воздействия окружающей среды.
                    </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 ">
                        <h3>Антикор РАСТ СТОП</h3>
                    <p>РАСТ СТОП - идеальное средство борьбы с коррозией канадской фирмы "Rust Stop inc." <br/>
                        Сейчас это наиболее эффективное средство.
                    </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <h3>Преимущества</h3>
                        <ul>
                        <li>Он не затвердевает - всегда находиться в полужидком состоянии, заполняя при этом все микротрещины и дефекты на металле.</li>
                        <li>Образует полимолекулярный слой, который подавляет коррозию</li>
                        <li>Не зависит от соблюдения режима полимеризации</li>
                        <li>Экологически чистый и не токсичный материал. Он безопасен для всех живых организмов, и не содержит растворителей</li>
                        <li>После установки отсутствуют неприятные запахи в салоне</li>
                        </ul>
                    </div>
                </div>
                

                

                <div className="container">
                    <Details/>
                </div>
            </div>
        </main>
    );
}
export default AnticorPage;