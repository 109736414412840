import React from 'react';
import Details from '../Details'

const GBOPage = (props) =>{
    return(
        <main>
            <div className="container mt-5">
                <div className="row row-header">
                    <div className="col-12">
                        <h1>Установка ГБО</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 ">
            
                    Сегодня одной из самой затратной статьи расходов на Ваше транспортное средство является расходы на топливо.
                    <br/>Чтобы не ограничивать себя в поездках, многие устанавливают ГБО для экономии средств.
                     В среднем полный комплект ГБО окупается в течение 12 месяцев, а дальше вы только экономите свои средства. Переход на газ никак не снижает мощности двигателя или ходовые характеристики автомобиля.
                </div>
                

                </div>

                <div className="container">
                    <Details/>
                </div>
            </div>
        </main>
    );
}

export default GBOPage;