import React from 'react';
import Details from '../Details'

const AutopartsPage = (props) =>{
    return(
        <main>
            <div className="container mt-5">
                <div className="row row-header">
                    <div className="col-12">
                        <h1>Автозапчасти</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 ">
            
                    Занимаемся подбором автозапчастей под ваше авто. 
                    <br/>Мы напрямую сотрудничаем с поставщиками, что позволяет нам подбирать запчасти по хорошей цене.
                    Занимаемся оригинальными деталями и их лицензионными заменителями.
                        
                </div>
                

                </div>

                <div className="container">
                    <Details/>
                </div>
            </div>
        </main>
    );
}

export default AutopartsPage;