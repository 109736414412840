import React,{Component, useEffect} from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import Header from '../Header';
import MainPage from '../Pages/MainPage';
import Footer from '../Footer';
import TintingPage from '../Pages/Tinting'
import AntigravPage from '../Pages/AntigravPage';
import StraightenPage from '../Pages/StraightenPage/StraightenPage';
import SignalizationPage from '../Pages/SignalizationPage/Signalization';
import AutoFromUSAPage from '../Pages/AutoFromUSA/AutoFromUSA';
import AnticorPage from '../Pages/AnticorPage';
import GBOPage from '../Pages/GBO';
import VinilPage from '../Pages/Vinil/VinilPage';
import AutopartsPage from '../Pages/Autoparts/AutopartsPage';
import SoundproofPage from '../Pages/SoundproofPage/Soundproof';
import ContactUsPage from '../Pages/ContactUsPage';

const Root = () => { 
    const history = useHistory() 
 
    useEffect(() => {

       return history.listen((location) => { 
        window.scrollTo({
            top: 0
        });
       }) 
    },[history]) 
    return(<></>);
 
 }

export default class App extends Component{
    
    render(){
       
        return(
            
            <Router>
                <Root/>
                <Header/>
                
                <Route path='/tinting' component={TintingPage}/>
                <Route path='/antigrav' component={AntigravPage}/>
                <Route path='/straight' component={StraightenPage}/>
                <Route path='/signalization' component={SignalizationPage}/>
                <Route path='/fromUSA' component={AutoFromUSAPage}/>
                <Route path='/anticor' component={AnticorPage}/>
                <Route path='/GBO' component={GBOPage}/>
                <Route path='/vinil' component={VinilPage}/>
                <Route path='/autoParts' component={AutopartsPage}/>
                <Route path='/noise' component={SoundproofPage}/>
                <Route path='/contactUs' component={ContactUsPage}/>
                <Route path='/' exact component={MainPage}/>
                <Footer/>
            </Router>
        );
    }
}