import React from 'react';
import Details from '../Details';

const SoundproofPage = (props) =>{
    return(
        <main>
            <div className="container mt-5">
                <div className="row row-header">
                    <div className="col-12">
                    <h1>Шумоизоляция</h1>
                    </div>
                </div>

                <div className="row">
                    
                    <div className="col-12 ">
                        <p>Шумоизоляция автомобиля защищает его от шумов двигателя, подвески, коробки передач, дороги. Все это мешает водителю и пассажирам. Кроме защиты от различных шумов, комплексная шумоизоляция выполняет ещё несколько функций:</p>
                        <ul>
                            <li className="">Препятствует теплообмену с окружающей средой, оставляя салон холодным в жару, и теплым  в холод</li>
                            <li className="">Улучшает качество звучания акустической системы</li>
                            
                        </ul>
                        <p>После шумоизоляции автомобиль лучше ведет себя в поворотах и не вибрирует от проезжающего крупногабаритного транспорта</p>
                    </div>
                </div>
                <div className="container">
                        <Details/>
                </div>
            </div>
        </main>
    );
}

export default SoundproofPage;