import React from 'react';
import Details from '../Details'

const VinilPage = (props) =>{
    return(
        <main>
            <div className="container mt-5">
                <div className="row row-header">
                    <div className="col-12">
                        <h1>Оклейка винилом</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 ">
            
                    Если вы хотите придать своему автомобилю индивидуальности, изменить цвет - оклейка винилом идеальный вариант для вас.
                    <br/>Покраска автомобиля обойдется дороже чем оклейка винилом.
                    Кроме этого, виниловая пленка может защитить ваш автомобиль от небольших повреждений.
                        Имеется возможность полной оклейки кузова, или определённых частей.    
                </div>
                

                </div>

                <div className="container">
                    <Details/>
                </div>
            </div>
        </main>
    );
}

export default VinilPage;