import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {Link} from 'react-router-dom'
import '../../node_modules/font-awesome/css/font-awesome.min.css'
import 'bootstrap-social'

const Footer = (props) =>{
    return(
        <footer className=" footer mt-auto position-sticky top-100">
            <Container>
                <Row>
                    <Col className="col-4 offset-1 col-sm-2">
                        <h5>Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/">Главная</Link></li>
                            <li><Link to="/tinting">Тонировка стекол</Link></li>
                            <li><Link to="/antigrav">Антигравийная защита</Link></li>
                            <li><Link to="/straight">Рихтовка автомобиля</Link></li>
                            <li><Link to="/fromUSA">Авто из США</Link></li>
                         </ul>
                    </Col>
                    <div className="col-7 col-sm-5">
                    <h5>Наш адресс</h5>
                        <address>
                            Кагамлыка 37а<br/>
                            <i className="fa fa-phone fa-lg"></i>:<br/>
                            <a href="tel:+380954315882">+380 95 431 5882</a>
                            
                        </address>
                    </div>
                
                </Row>
                <div className="row justify-content-center">
                <div className="col-auto">
                    <p>© Copyright 2021 SaturnAvto</p>
                </div>
            </div>
            </Container>
         </footer>
    );
}

export default Footer;